/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 50px;
  background: #000;
  color: #f7f7f7;
  position: sticky;
  top: 0;
  z-index: 5000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.navbar-brand img {
  width: 50px;
  height: 50px;
}

.navbar-brand h3{
  margin-left: 10px;
}

.navbar-book{
  color: #fff;
  position:relative;
  text-decoration: none;
  padding: 10px;
  background: transparent;
  border: 0.2px solid var(--primary-color)
}

.navbar .auth{
  position:absolute;
  top: 30px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.navbar .auth-btn, .navbar button{
  text-decoration: none;
  padding: 8px 10px;
  border-radius: 8px;
  background: var(--bg3);
  color: var(--primary-color)
}

.navbar .auth-btn{
  margin-left: 10px;
}



.navbar-toggle {
  font-size: 1.5rem;
  cursor: pointer;
  display: none; /* Hide toggle on larger screens */
}

.navbar-toggle:hover{
  color: var(--primary-color);
}

.navbar-menu {
  display: flex;
  list-style: none;
  gap: 20px;
  transition: transform 0.3s ease;
}

.navbar-menu li {
  padding: 10px 0;
}

.navbar-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-menu a:hover {
  color: var(--primary-color);
}

/* Desktop View */
@media (min-width: 768px) {
  .navbar-menu {
    transform: translateX(0);
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .navbar {
    height: auto;
  }

  
.navbar-book{
  top: 20px;
  right: 70px;
  position: absolute;
}

.navbar .auth{
  position:absolute;
  top: 20px;
  left: 40%;
}

  .navbar-toggle {
    display: block;
    z-index: 4444;
  }

  .navbar-menu {
    position: fixed;
    top: -15px;
    right: 0;
    width: 60%;
    height: 100vh;
    background: #111;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    transform: translateX(100%); /* Initially off-screen */
    transition: transform 0.4s ease; /* Slide-in transition */
    z-index: 1000;
  }

  .navbar-menu.open {
    transform: translateX(0); /* Slide in when open */ 
  }

  .navbar-menu li {
    padding: 10px;
    width: 80%;
    border-bottom: 0.1px solid var(--primary-color);
  }
}
